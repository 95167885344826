.calculator-select {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;

  &__trigger {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    padding: 0 15px;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    color: #25282b;
    border: 1px solid #d4d6db;
    background-color: #fff;

    &_arrow {
      &--opened {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &--opened {
      border-radius: 7px 7px 0 0;
    }
  }

  &__title {
    position: relative;
    color: #25282b;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    top: 9px;
    right: 3px;

    &--placeholder {
      top: 0;
    }
  }

  &__label {
    display: block;
    color: #9496ac;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: 5px;
    left: 12px;
  }

  &__options {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    right: 0;
    border-radius: 0 0 8px 8px;
    border: solid 1px #e8ecf1;
    transition: all 0.5s;
  }

  &__option {
    position: relative;
    display: block;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #e8ecf1;
    background-color: #fff;
    transition: all 0.5s;

    &--last {
      border-radius: 0 0 8px 8px;
      border-bottom: 0;
    }
  }
}
