.space-section {
  padding: 15px 0;

  &--large-space {
    padding: 20px 0;
  }

  &--bottom-space {
    padding: 0 0 15px;
  }

  &--no-bottom-space {
    padding: 0;
  }

  &--large-bottom-space {
    padding: 0 0 20px;
  }

  &--bottom-divider {
    border-bottom: 1px solid #eae5e1;
  }

  &--top-divider {
    border-top: 1px solid #eae5e1;
  }

  &--large-top-space {
    padding: 20px 0 0;
  }
}
