.profile-settings {
  color: $colour-cocoa-brown;

  a {
    text-decoration: none;
  }

  &__language {
    display: flex;
    gap: 10px;
    align-items: center;
    height: 55px;
    width: 100%;
    cursor: pointer;
    color: $colour-cocoa-brown;
    border-bottom: 0;
    padding: 0;
    background-color: $colour-white;
  }

  &__navigations {
    background-color: $colour-white;
    border-top: 1px solid $colour-grey-wild-sand;
    border-bottom: 1px solid $colour-grey-wild-sand;
    margin: 15px 0;

    :last-child {
      &::after {
        border-bottom: 0;
      }
    }
  }

  &__title {
    font-size: 15px;
  }

  &--border-bottom {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid $colour-grey-wild-sand;
    }
  }

  &--bottom-middle-border {
    width: 100%;
    padding: 0 15px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      border-bottom: 1px solid $colour-grey-wild-sand;
      width: calc(100% - 30px);
    }
  }

  &__logout {
    span {
      width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid $colour-grey-wild-sand;
      width: 100%;
      display: block;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid $colour-grey-wild-sand;
      width: 100%;
      display: block;
    }
  }
}
