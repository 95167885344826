.error {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url('/static/images/error-page-desc.svg');
  background-size: contain;
  background-color: #fff;
  color: #525252;
  text-align: center;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  padding-top: 20px;

  &-logo {
    display: inline-block;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    width: 200px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('/static/images/logo-error-page.svg');
    margin-bottom: 18px;
  }

  &-status {
    font-size: 210px;
    font-weight: 600;
    line-height: 176px;
    letter-spacing: 3.9px;
    margin: 35px 0;
  }

  &-text {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: #525252;
    padding: 0 10px;
  }

  &-link {
    display: inline-block;
    border-radius: 4px;
    background: #ce312d;
    margin: 40px auto 0;
    padding: 0 20px;
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 15px;
    line-height: 50px;
    font-weight: bold;
    letter-spacing: 0.6px;
    text-decoration: none;
  }
}

@media screen and (max-width: 480px) {
  .error {
    background-image: url('/static/images/error-page.svg');
    background-size: contain;

    &-logo {
      width: 130px;
      height: 38px;
      margin: 0 auto;
    }

    &-status {
      font-size: 140px;
      line-height: 176px;
      margin: 25px 0 0;
    }

    &-text {
      margin-top: 0;
      font-size: 22px;
    }

    &-link {
      margin: 47px auto 0;
    }
  }
}
