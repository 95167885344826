$rotate-icon: '/static/images/new_ad/icons/icon-rotate.svg';
$delete-icon: '/static/images/new_ad/icons/icon-delete-photo.svg';

.edit-item {
  &__info {
    margin-top: 60px;
    padding: 0 15px 15px;
    background-color: $colour-white;

    &-header {
      color: $colour-cocoa-brown;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
      margin-top: 15px;
    }

    &-subtitle {
      font-size: 14px;
      font-weight: 400;
      color: $colour-heathered-grey;
    }
  }
}
