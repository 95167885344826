.profile-business-packages {
  background-color: $colour-white;
  border-top: 1px solid $colour-grey-wild-sand;

  .profile-modal__item {
    padding: 15px;
    background: $colour-grey-desert-storm;

    &:last-of-type {
      padding: 15px 20px;
    }
  }

  .profile-modal__item-header {
    margin-bottom: 5px;
  }

  .profile-modal__item-description {
    font-size: 12px;

    &:last-child {
      font-size: 14px;
    }
  }

  .profile-modal__item:last-of-type {
    background-color: $colour-white;
  }

  &__personal-account {
    padding: 20px 15px;

    &_title {
      display: block;
      color: $colour-cocoa-brown;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    button {
      margin-top: 15px;
    }
  }
}
