.not-found-section {
  width: 100%;
  padding: 40px 15px;
  background-color: #fff;
  border-bottom: 1px solid #eae5e1;

  i {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  span {
    display: block;
    margin-bottom: 15px;
    text-align: center;
    font-family: $helvetica;
  }

  &__title {
    color: #2f1f19;
    font-size: 18px;
    font-weight: 500;
  }

  &__desc {
    color: #98918a;
    font-size: 15px;
    font-weight: 400;
  }

  &__container {
    height: 90vh;
    padding: 30% 15px;
    border-bottom: 0;
  }

  &__btn {
    a {
      text-decoration: none;
      text-underline: none;
      display: block;
    }

    &-link {
      width: 100%;
      padding: 15px;
      height: 48px;
      color: #fff;
      text-align: center;
      border-radius: 7px;
      background-color: #4264fd;
    }
  }
}
