$color: #2f1f19;

.price-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 15px;
  color: $color;
  font-family: $helvetica;
  gap: 10px;

  &__i {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 3px;

    @media screen and (max-width: 350px) {
      font-size: 18px;
    }

    &-cur {
      margin-left: 3px;
    }
  }

  &__area {
    font-size: 16px;
    font-weight: 400;
  }

  &__btn {
    width: 160px;
    height: 40px;
    border-radius: 8px;
    padding: 10px;
    color: #fff;
    background-color: #ec3342;
    font-weight: 500;
    font-size: 16px;
    font-family: $helvetica;

    @media screen and (max-width: 350px) {
      width: 120px;
      font-size: 12px;
    }
  }
}
