.new-map-container {
  margin-top: 15px;
  height: 140px;
  border-radius: 14px;
  overflow: hidden;
  width: 100%;
  position: relative;

  &.is-full-screen {
    height: 100dvh;
    width: 100vw;
    position: fixed;
    top: -15px;
    left: 0;
    z-index: 9999;
    border-radius: 0;
  }

  &__close {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 45px;
    height: 45px;
    background-color: $colour-white;
    opacity: 0.7;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10000;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include arrow(15px, $colour-cocoa-brown, 3px) {
      transform: rotate(135deg);
    }
  }

  &__save {
    position: absolute;
    bottom: 34px;
    margin: 0 15px;
    background-color: $colour-neon-blue;
    display: flex;
    width: calc(100% - 30px);
    height: 50px;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    z-index: 10000;
    color: $colour-white;
    font-size: 15px;
  }

  &__overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border-radius: 14px;
      border: 1px solid $colour-neon-blue;
      color: $colour-neon-blue;
      background-color: $colour-white;
      padding: 10px 15px;
      display: flex;
      font-size: 15px;
      justify-content: center;
      align-items: center;
    }
  }
}
