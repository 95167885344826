.calc-success {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: $helvetica;
  color: #2e3131;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  padding-top: 80px;
  margin-bottom: 10px;

  i {
    display: block;
  }

  span {
    display: block;
  }
}
