.calculator-check {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;

  &__trigger {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    padding: 0 15px;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    color: #25282b;
    border: 1px solid #d4d6db;
    background-color: #fff;

    &_arrow {
      &--opened {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &--opened {
      border-radius: 7px 7px 0 0;
    }
  }

  &__options {
    display: flex;
  }

  &__option {
    position: relative;
    display: block;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    background-color: #f3f3f5;
    border-radius: 24px;
    color: #25282b;
    margin-right: 12px;

    &--selected {
      background-color: #ec3342;
      color: #fff;
    }
  }
}
