.operation-button {
  width: 100%;

  &__base-button {
    width: 100%;
    padding: 15px;
    border-radius: 7px;
    background-color: #4264fd;
    color: #fff;
    font-family: $helvetica;
    font-size: 15px;
  }
}
