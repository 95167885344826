$color: #2f1f19;

.agency-info {
  $this: &;

  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 15px;
  gap: 10px;
  background-color: #fff;

  &__content {
    display: flex;
    gap: 10px;
    align-self: center;
    font-family: $helvetica;

    &-logo {
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
      border-radius: 7px;
      border: 1px solid #eae5e1;
      overflow: hidden;
      align-self: center;
    }

    &-info {
      width: 75%;
      align-self: center;
    }

    &-title {
      color: $color;
      font-size: 16px;
      font-weight: 500;

      h3 {
        line-height: 0;
        margin-bottom: 5px;
      }
    }

    &-offers {
      color: #98918a;
      font-size: 14px;
    }
  }

  &__arrow {
    align-self: center;
  }

  &--webview {
    @extend #{$this};
    padding: 0;
    margin-top: 20px;

    &__content {
      @extend #{$this}__content;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;

      &-logo {
        @extend #{$this}__content-logo;
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &-title {
        @extend #{$this}__content-title;
        margin-bottom: 2px;
      }
    }

    &__arrow {
      display: none;
    }
  }
}
