/* stylelint-disable */
/* Make clicks pass-through */

.nprogress--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}
#nprogress {
  pointer-events: none;
  background: #b4d8f3;
  z-index: 1031;
  @extend .nprogress--fixed;

  .bar {
    background: #4264fd;
    z-index: 1032;
    @extend .nprogress--fixed;
  }
}
