.profile-promotion-items {
  &__header {
    position: relative;
    background-color: #e45359;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding: 5px 9px;
    line-height: 34px;
    color: $colour-white;

    i {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__list {
    background-color: $colour-white;
    padding: 0 9px 13px;

    &-item {
      position: relative;
      width: 100%;
      padding: 9px;
      margin-top: 19px;
      color: #d63a47;
      font-size: 13px;
      font-weight: bold;
      border-radius: 4px;
      border: 1px solid #d63a47;
      background-color: $colour-white;
      line-height: 22px;
      text-decoration: underline;
      text-align: center;

      &::before {
        content: '';
        display: inline-block;
        margin-right: 8px;
        background-repeat: no-repeat;
        vertical-align: -2px;
      }

      &--bump {
        &::before {
          background-image: url('/static/images/profile/promotion/bump.svg');
          width: 18px;
          height: 18px;
        }
      }

      &--vip {
        &::before {
          background-image: url('/static/images/profile/promotion/vip.svg');
          vertical-align: -3px;
          width: 20px;
          height: 18px;
        }
      }

      &--feature {
        &::before {
          background-image: url('/static/images/profile/promotion/feature.png');
          width: 20px;
          height: 16px;
        }
      }

      &_price {
        position: absolute;
        z-index: 1;
        right: -5px;
        bottom: 100%;
        margin-bottom: -9px;
        border-radius: 4px;
        padding: 4px 9px;
        background: #d63a47;
        color: $colour-white;
        line-height: normal;
        font-size: 12px;
      }
    }
  }
}
