.profile-chip-button {
  width: 100%;
  height: 38px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $colour-white;
  border: 1px solid $colour-grey-wild-sand;
  font-size: 15px;

  &--active {
    color: $colour-neon-blue;
    border: 1px solid $colour-neon-blue;
    background-color: $colour-light-blue;
  }
}
