.swiper-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-track {
  display: flex;
  transition: 0.3s ease-in-out;
  height: 100%;
}

.swiper-slide {
  flex: 0 0 100%;
  height: 100%;
  overflow: hidden;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.swiper-prev,
.swiper-next {
  $size: get-vw(21px);
  position: absolute;
  top: 50%;
  width: $size;
  height: $size;
  margin-top: -($size / 2);
  z-index: 11;
  outline: none;
  border: 0;
  border-radius: 50%;
  background-size: cover;
  background-color: transparent;

  @include mq(480) {
    $size: 24px;
    width: $size;
    height: $size;
    margin-top: -($size / 2);
  }
}

.swiper-prev {
  background-image: url('/static/images/item-slider-arrow-left.svg');
  left: get-vw(10px);
  right: auto;

  @include mq(480) {
    left: 10px;
  }
}

.swiper-next {
  background-image: url('/static/images/item-slider-arrow-right.svg');
  right: get-vw(10px);
  left: auto;

  @include mq(480) {
    right: 10px;
  }
}
