.apartments-modal {
  &__content {
    padding: 20px 15px;
  }

  &__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #2f1f19;
    padding: 20px 0;
    border-bottom: 1px solid #eae5e1;
  }

  &__name {
    &-value {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #2f1f19;
      margin-bottom: 10px;
    }
  }

  &__price {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #2f1f19;
    display: flex;
    margin-bottom: 10px;

    .price-cur {
      margin: 0 5px;
    }

    &-per-meter {
      margin-left: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #98918a;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #2f1f19;
    margin-bottom: 10px;

    span:not(:first-of-type)::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      background: #eae5e1;
      border-radius: 50%;
      margin: 0 5px;
      position: relative;
      bottom: 2px;
    }
  }

  &__gallery {
    &-img {
      img {
        width: 345px;
        height: 245px;
        border: 1px solid #eae5e1;
        border-radius: 7px;
        margin: 0 auto;
      }
    }

    &-thumbnails {
      img {
        width: 112px;
        height: 63px;
      }

      .slick-current img {
        border: 1px solid #4264fd;
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 6px 5px 4px;
    font-size: 32px;
    line-height: 19px;
    font-weight: 400;
    color: #98918a;
    text-decoration: none;
  }
}
