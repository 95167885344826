.gallery {
  background-color: #323232;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate(0);

  &__container {
    position: relative;
  }

  &__info-views {
    position: absolute;
    right: 15px;
    top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    background-image: url('/static/images/residences/views.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    padding-left: 25px;
    z-index: 1;
  }

  &__row {
    display: flex;
    width: max-content;
    width: 100%;

    .slick {
      &-slider {
        width: 100%;
      }

      &-list {
        height: 330px;
      }
    }
  }

  &__thumbnail {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 330px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;

    &:last-child {
      margin-right: 0;
    }

    &-img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &__bg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .slick-arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
    background: url('/static/images/residences/left-slider.svg');
    border-radius: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-size: 0;

    &::before {
      content: none;
    }

    &.slick-disabled {
      display: none !important;
    }

    &:hover,
    &:focus {
      background: url('/static/images/residences/left-slider.svg') no-repeat
        center/contain;
    }
  }

  .slick-next {
    right: 15px;
    left: auto;
    transform: translate(0, -50%) rotate(180deg);
    background: url('/static/images/residences/left-slider.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
