.phones {
  &-container {
    padding: 0 10px;
  }

  &__link,
  &__close-btn {
    display: block;
    color: #2389fa;
    font-size: 17px;
    font-weight: normal;
    text-decoration: none;
    text-align: center;
    padding: 11px 0;
    border-bottom: 1px solid #dbdbdb;
    background-color: #f2f2f7;
    margin-bottom: 8px;
    border-radius: 14px;
  }
}
