.section {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    box-sizing: border-box;
    margin: 0 0 10px;

    &.bookmarks {
      margin-top: -12px;
      margin-left: 0;
      margin-right: 0;
    }

    .thumblist-title {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }

    .thumblist-premium-title {
      font-size: 11px;
      font-weight: 400;
      color: #bcbcbc;
      margin: 0;
      text-align: right;
    }

    .show-all {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #3c5efd;
      text-decoration: none;
    }

    .items-header--items-found {
      font-size: 14px;
      line-height: 16px;
      color: #9b9b9b;
    }
  }
}
