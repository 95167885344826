.development {
  &__i {
    padding-left: 10px;
    position: relative;
    font-size: 15px;
    line-height: 20px;
    color: #2f1f19;
    display: inline-block;

    &--container {
      display: flex;
      align-items: center;
    }

    &--img {
      width: 60px;
      height: 60px;
      padding: 0;
    }

    &--developer {
      font-family: $helvetica;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }
  }
}
