.profile-payment-item {
  background-color: $colour-white;
  padding: 25px 15px;
  border-bottom: 1px solid $colour-grey-wild-sand;

  &-container {
    background-color: $colour-background-tertiary;
    height: calc(100vh - 60px);
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__content {
    &_row {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      font-size: 15px;
      padding: 0 10px;
      margin-bottom: 12px;

      span {
        display: block;
      }

      &-title {
        color: $colour-heathered-grey;
      }

      &-value {
        color: $colour-cocoa-brown;
      }

      &:last-child span {
        font-weight: 500;
        color: $colour-cocoa-brown;
      }

      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      &:last-child {
        margin: 20px 0 0;
        border-top: 1px solid $colour-grey-wild-sand;
        padding-top: 20px;
      }
    }
  }
}
