.user-data {
  display: flex;
  height: 60px;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background-color: $colour-background-tertiary;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;

  &__content {
    display: flex;
    gap: 10px;
    width: calc(100% - 100px);
    align-self: center;

    &-logo {
      align-self: center;
    }

    &-info {
      align-self: center;
      max-width: calc(100% - 40px);

      h3 {
        line-height: normal;
        margin-bottom: 2px;
      }
    }

    &-phone {
      color: $colour-cocoa-brown;
      font-size: 16px;
      font-weight: 500;
    }

    &-email {
      color: $colour-heathered-grey;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      display: -webkit-box; // stylelint-disable-line  value-no-vendor-prefix
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
    }
  }

  &__links {
    width: 100px;
    display: flex;
    justify-content: flex-end;

    &-icon {
      height: 60px;
      width: 20px;
      margin-left: 25px;
      position: relative;

      i {
        position: absolute;
        top: calc(50% - 10px);
      }
    }
  }
}
