.profile-invoices {
  padding: 20px 15px;
  height: 100%;

  &__title {
    display: block;
    color: $colour-cocoa-brown;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__dropdown {
    border-radius: 20px;
    border: 1px solid $colour-grey-wild-sand;
    background: $colour-white;
    padding: 0 15px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;

    span {
      display: block;
      margin-right: 5px;
      color: $colour-cocoa-brown;
      font-size: 15px;
    }

    i {
      display: block;
      position: relative;
      width: 25px;
      height: 100%;

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &__modal-header {
    border-bottom: 0;
    margin-bottom: 10px;
  }

  &__radio-button {
    border-bottom: 1px solid $colour-grey-wild-sand;
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
  }

  &__empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 128px);
  }
}
