.awaiting-invoices {
  margin: 15px 0;
  padding: 10px 15px;
  width: 100%;
  border-radius: 7px;
  border: 1px solid $colour-grey-wild-sand;
  background: $colour-white;
  align-items: center;
  position: relative;

  &__info {
    display: block;
    margin-left: 26px;
    color: $colour-neon-blue;
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      left: 15px;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-image: url('/static/images/profile/bell.svg');
      background-size: contain;
    }
  }

  &__icon-button {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}
