.breadcrumbs {
  $this: &;
  width: 100%;
  overflow: hidden;

  &__white {
    color: $colour-cocoa-brown;
    background-color: #fff;
  }

  &-list {
    padding: 15px 10px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 0;

    &--vip {
      padding: 10px 15px;
    }
  }

  &-i {
    position: relative;
    display: inline-block;
    margin-right: 22px;
    color: $colour-heathered-grey;
    font-size: 14px;
    line-height: 1;

    a {
      color: $colour-heathered-grey;
      text-decoration: none;

      &::after {
        content: '';
        width: 3px;
        height: 3px;
        background-color: #8d94ad;
        background-image: none;
        border-radius: 50%;
        position: absolute;
        right: -12px;
        top: 7px;
      }
    }

    p {
      color: $colour-cocoa-brown;
      margin: 0;
    }
  }

  &-residences {
    background: #f6f4f1;
    border: 1px solid #eae5e1;

    #{$this}-i {
      a,
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: #98918a;
      }
    }
  }
}
