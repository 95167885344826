.profile {
  font-family: $helvetica;
  background-color: $colour-background-tertiary;
  min-height: 100vh;

  &__modal-header {
    border-bottom: 0;
    margin-bottom: 10px;
  }

  &__modal-button {
    height: 50px;
    border-radius: 14px;
    background-color: $colour-neon-blue;
    margin-bottom: calc(env(safe-area-inset-bottom) + 20px);
  }
}
