.main-loader {
  display: flex;
  align-items: center;
  justify-content: center;

  &-span {
    $fill: #946952;
    $size: 27px;
    $border: 2px;
    opacity: 0.75;
    vertical-align: middle;
    width: $size;
    height: $size;
    border-radius: 50%;
    border-top: $border solid transparent;
    border-right: $border solid $fill;
    border-bottom: $border solid $fill;
    border-left: $border solid $fill;
    transform: translateZ(0);
    animation: rotation 1s infinite linear;
  }

  &-spinner {
    z-index: 2;
    width: 20px;
    height: 20px;
    animation: rotation 1s infinite linear;
    background-image: url('/static/images/icon-spinner.svg');
  }

  &-page {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    margin: 0;
    z-index: 10;

    &__search {
      margin-top: auto !important;
      margin-bottom: -get-vw(55px) / 2;
    }
  }

  &__header {
    margin: 0 auto;
    height: 21.25vw;
  }
}

.endless-loader {
  padding: 10px 0;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
