.leased-modal {
  @include custom-scrollbar(54px);
  max-height: 85vh;
  width: 100%;
  background-color: $colour-white;
  border-radius: 7px 7px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  padding-right: 8px;

  &--create {
    border-radius: 7px 7px 0 0;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99;
  }

  &__header {
    text-align: center;
    position: relative;
    padding: 28px 0 13px 15px;
    font-weight: 500;
    font-size: 16px;

    &__close-btn {
      position: absolute;
      top: 30px;
      right: 7px;
      cursor: pointer;
      padding: 0;
      background-color: $colour-white;
    }

    &__back-btn {
      width: 17px;
      height: 17px;
      position: absolute;
      top: 28px;
      left: 12px;
      padding: 0;
      background-color: transparent;

      @include arrow(12px, $colour-heathered-grey, 2px) {
        transform: rotate(135deg);
      }
    }
  }

  &__content {
    padding: 0 7px 39px 15px;
    overflow-y: auto;
    max-height: 74vh;
    -webkit-overflow-scrolling: touch;
    margin-top: 20px;

    &--create {
      padding-bottom: 34px;
    }
  }
}
