.read-more {
  width: 100%;
  font-size: 15px;
  line-height: 25px;
  color: #2f1f19;
  font-weight: 400;
  white-space: pre-wrap;

  &--pruned {
    max-height: 150px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box; // stylelint-disable-line  value-no-vendor-prefix
    -webkit-line-clamp: 6;
    line-clamp: 6;
    -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix

    article {
      height: fit-content;
    }
  }

  &__btn {
    line-height: 1.5;
    font-weight: 400;
    font-size: 15px;
    text-decoration-line: underline;
    color: #4264fd;
  }
}
