.calc-alert-modal {
  position: relative;
  padding: 30px 15px 15px;

  i {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
  }

  span {
    display: block;
    color: #09101d;
    font-family: $helvetica;
    font-size: 14px;
    font-weight: 500;
  }
}
