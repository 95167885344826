.bonus-promotion {
  position: relative;
  padding: 10px;
  background-color: #f6f4f1;
  border-radius: 7px;
  text-align: left;
  line-height: 1;

  &__row {
    display: flex;
    align-items: center;
    gap: 5px;

    & + & {
      margin-top: 6px;
    }
  }

  &__name {
    width: 60px;
    font-size: 14px;
    color: #98918a;

    @media only screen and (max-width: 360px) {
      :lang(ru) & {
        font-size: 12px;
        width: auto;
        max-width: 60px;
      }
    }
  }

  &__value {
    display: flex;
    gap: 3px;
    align-items: center;
    line-height: 13px;
    padding: 5px 7px;
    color: #2f1f19;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 30px;

    &--crossed {
      text-decoration: line-through;
    }

    &--label {
      letter-spacing: 0.55px;
      text-transform: uppercase;
      background-color: #fff;
    }

    @media only screen and (max-width: 360px) {
      :lang(ru) & {
        font-size: 9px;
      }
    }
  }

  &__label {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 7px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.55px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0 7px;

    &--vip {
      background-color: #ff3d00;
    }

    &--feature {
      background-color: #ff9f2b;
    }
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 13px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @each $icon in (bump, vip, feature) {
      &--#{$icon} {
        background-image: url('/static/images/services/#{$icon}.svg');
      }
    }
  }
}
