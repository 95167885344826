.item {
  &-section {
    margin-bottom: 5px;
    padding: 10px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 #dbd8d8;

    &__similar-items {
      padding: 0 10px;
      margin: 0 0 10px;
    }
  }
}
