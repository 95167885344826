.contacts {
  &-container {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    z-index: 1;
  }

  &__title {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #fff;
    margin-bottom: 15px;
  }

  &-i {
    display: flex;

    &__items {
      padding: 10px 15px;
      margin-right: 5px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 29px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #fff;
    }
  }
}
