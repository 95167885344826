.active-package-section {
  margin-bottom: 15px;
  padding: 0 15px;
  background-color: #f8f8f8;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
    background-color: #f6f7fa;
  }

  &__head {
    display: flex;
    justify-content: space-between;

    &-dropdown {
      display: inline-flex;
      align-items: center;

      span {
        display: block;
        color: $colour-cocoa-brown;
        font-size: 15px;
        font-weight: 700;
        margin-right: 10px;
      }

      i {
        display: block;
        position: relative;
        width: 17px;
        height: 100%;

        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &-discount {
      display: flex;
      height: 18px;
      padding: 3px 10px;
      justify-content: center;
      align-items: center;
      border-radius: 45px;
      background-color: #3db460;
      font-size: 12px;
      color: $colour-white;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: $colour-heathered-grey;
      font-size: 14px;

      @media screen and (max-width: 350px) {
        font-size: 10px;
      }

      &-data {
        color: $colour-cocoa-brown;
        font-size: 16px;
        font-weight: 500;
        margin-top: 5px;

        @media screen and (max-width: 350px) {
          font-size: 12px;
        }
      }

      span {
        display: block;
      }
    }
  }

  &__button {
    font-size: 15px;
    height: 50px;
    color: $colour-cocoa-brown;
    background-color: $colour-white;
  }
}

.active-package-info-modal {
  button {
    margin-bottom: 20px;
  }

  .profile-package {
    padding: 20px;
  }

  .profile-package__content {
    margin-bottom: 0;
  }
}
