.city {
  &__loader {
    margin: 0;
    text-decoration: underline;

    span {
      width: 15px;
      height: 15px;
      margin: 0;
    }
  }
}
