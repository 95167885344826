$border: 1px solid $colour-grey-wild-sand;

.terminal-modal {
  color: $colour-cocoa-brown;

  &__user-data {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    background-color: $colour-white;
    color: $colour-heathered-grey;
    border-bottom: $border;

    span {
      display: block;
      font-size: 14px;

      &:last-child {
        width: 100%;
        padding: 10px;
        color: $colour-dark-tan;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        border-radius: 7px;
        background-color: $colour-placeholder-bg;
      }
    }
  }

  &__content {
    padding: 15px;
    border-top: $border;
    border-bottom: $border;
    background-color: $colour-white;

    &-title {
      font-size: 15px;
      font-weight: 700;
    }

    &-list {
      display: flex;
      gap: 15px;
      flex-direction: column;
      margin: 20px 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-color: $colour-grey-wild-sand;
        left: 15px;
        width: 1px;
        height: 100%;
      }

      &-item {
        display: flex;
        gap: 10px;
        min-height: 30px;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        position: relative;

        &__step {
          height: 30px;
          min-width: 30px;
          border-radius: 50%;
          border: 1px solid $colour-grey-wild-sand;
          color: $colour-heathered-grey;
          font-size: 14px;
          font-weight: 400;
          position: relative;
          z-index: 1;
          background-color: $colour-white;
        }

        &__step span {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &__title {
          align-items: center;
        }
      }

      .inner-step {
        margin-left: 40px;
        // stylelint-disable-next-line
        &::before {
          content: '';
          position: absolute;
          background-color: $colour-grey-wild-sand;
          left: -25px;
          width: 25px;
          height: 1px;
        }
      }
    }

    &-info {
      margin-bottom: 15px;
      color: #2f1f19;
      font-size: 14px;
      font-weight: 400;
      border-top: solid 1px #eae5e1;
    }

    &-bottom {
      position: relative;
      display: flex;
      align-items: center;
      color: #2f1f19;
      font-size: 14px;
      font-weight: 400;
      padding: 15px 30px 15px 53px;
      border-top: solid 1px #eae5e1;

      &::before {
        content: '';
        display: block;
        width: 23px;
        position: absolute;
        height: 21px;
        left: 15px;
        background-image: url('/static/images/wallet-terminal.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  &__phone {
    color: #d93d04;
  }
}
