$title-color: #98918a;
$background-color: #f8f8f8;
$border-color: #eae5e1;

.display-none {
  display: none;
}

.divider {
  height: 1px;
  border-bottom: 1px solid $border-color;

  &--error {
    border-bottom: 1px solid $colour-red;
  }
}

.divider-error {
  height: 1px;
  border-bottom: 1px solid $colour-red;
}

.filters {
  &__options-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__section {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    padding: 0 15px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid $border-color;
    border-top: 0.5px solid $border-color;
  }

  &__leased_type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid $colour-grey-wild-sand;
    height: 38px;
    font-size: 15px;
    color: #2f1f19;
    margin: 15px 0;

    &-active {
      background-color: $colour-cocoa-brown;
      border-color: $colour-cocoa-brown;
      color: $colour-white;

      &_blue {
        background-color: $colour-light-blue;
        border-color: $colour-neon-blue;
        color: $colour-neon-blue;
      }
    }
  }

  &__link {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 15px;
    color: $colour-neon-blue;
    text-decoration: none;
    background-color: transparent;
  }

  &__option {
    display: flex;
    width: 50%;
    -webkit-tap-highlight-color: transparent;
    outline: none;

    &:first-child .filters__leased_type {
      border-radius: 14px 0 0 14px;
    }

    &:last-child .filters__leased_type {
      border-radius: 0 14px 14px 0;
    }
  }

  &--no-border-top {
    border-top: 0;
  }
}
