.calc-form {
  font-family: $helvetica;

  &__block {
    margin-bottom: 20px;
    position: relative;
  }

  &__group {
    position: relative;
    margin-bottom: 20px;

    &-label {
      position: absolute;
      color: #9496ac;
      font-size: 14px;
      z-index: 1;
      opacity: 0.8;
      font-weight: 500;
      top: 5px;
      left: 12px;
    }

    &-prefix {
      position: absolute;
      left: 12px;
      top: 27px;
    }

    &-inpt {
      width: 100%;
      position: relative;

      .phone-input {
        padding-left: 53px;
      }

      input {
        width: 100%;
        height: 56px;
        padding: 15px 12px 0;
        color: #25282b;
        border-radius: 10px;
        border: 1px solid #d4d6db;
        font-size: 16px;
      }

      input:focus {
        outline: none;
        border: 1px solid #d4d6db;
      }

      input::placeholder {
        width: 70%;
        color: #2e3131;
        font-size: 16px;
      }

      &--error {
        position: absolute;
        display: flex;
        align-items: center;
        right: 15px;
        left: 12px;
        top: 20px;
        height: 35px;
        background-color: #fff;
        color: #d80516;
        font-size: 16px;
        font-weight: 400;
      }

      &--unnecessary {
        color: #09101d;
        font-size: 15px;
        font-weight: 500;
        display: block;
        margin-top: 10px;
      }
    }
  }
}
