.params-modal {
  &__content {
    margin: 20px 15px;

    &-facilities {
      padding-top: 20px;
      margin: 0 15px 20px;
      border-top: 1px solid #eae5e1;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2f1f19;
    padding: 20px 0;
    border-bottom: 1px solid #eae5e1;
  }

  &__items {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #98918a;
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    font-family: $helvetica;

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #2f1f19;
      margin-bottom: 20px;
    }

    &-left,
    &-right {
      flex: 1;

      li:not(:last-child) {
        margin-bottom: 10px;
      }

      li {
        display: flex;
        align-items: center;
      }
    }

    &-right {
      color: #2f1f19;
    }

    &-facility-i {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #2f1f19;
      display: flex;
      align-items: center;
      margin-bottom: 17px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 16px;
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 6px 5px 4px;
    font-size: 32px;
    line-height: 19px;
    font-weight: 400;
    color: #98918a;
    text-decoration: none;
  }
}
