.search-checkbox {
  $search-checkbox: &;

  &:checked {
    ~ .search-checkbox__text {
      color: $colour-neon-blue;
    }

    ~ .search-checkbox__mark {
      background-image: url($base64-tickmark);
      background-color: $colour-neon-blue;
      border-color: $colour-neon-blue;
    }
  }

  &-label {
    display: block;
    padding-left: 20px;

    + .location-tabs__title {
      margin-top: -1px;
      z-index: 1;
    }

    &--region {
      background-color: $colour-grey-black-squeeze;
      border-top: 1px solid $colour-grey-wild-sand;
      border-bottom: 1px solid $colour-grey-wild-sand;

      #{$search-checkbox}-container {
        border-bottom: 0;
      }

      #{$search-checkbox}__text {
        font-weight: 500;
        color: $colour-cocoa-brown2;
      }
    }

    &--new-region {
      padding-left: 0;

      #{$search-checkbox}-container {
        border-bottom: 0;
      }

      #{$search-checkbox}__text {
        font-weight: 400;
        color: $colour-cocoa-brown2;
      }
    }

    + #{&}--region {
      margin-top: -1px;
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding-right: 20px;
    border-bottom: 1px solid $colour-wild-sand;
  }

  &__text {
    font-size: 15px;
    color: $colour-cocoa-brown;
  }

  &__mark {
    position: relative;
    display: inline-block;
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid $colour-white-smoke;
    transition: background-color 0.1s ease-in-out;
    box-sizing: border-box;
    background-color: $colour-white;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__group-type {
    font-size: 12px;
    line-height: 1.5;
    color: $colour-heathered-grey;
  }
}
