.profile-navbar {
  display: flex;
  justify-content: center;
  padding: 0 15px 10px;
  gap: 25px;
  background-color: $colour-background-tertiary;

  &__new-badge {
    position: absolute;
    bottom: -5px;
    width: 43px;
    height: 19px;
    color: $colour-white;
    background-color: $colour-green;
    border-radius: 20px;
    font-size: 11px;
    font-style: normal;
    display: flex;
    padding: 3px 7px;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translate(-50%);
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 83px;
    height: 83px;
    text-decoration: none;

    &-circle {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: $colour-white;
      box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
      margin-bottom: 5px;
      position: relative;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-title {
      text-align: center;
      color: $colour-cocoa-brown;
      font-size: 12px;
    }
  }
}
