.params {
  &__title {
    margin-bottom: 22px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2f1f19;
  }

  &__items {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #2f1f19;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    font-family: $helvetica;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    span {
      flex: 1;

      &:first-of-type {
        $icons: deadline, class, buildings, blocks, flats, elevators, floors;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #98918a;
        display: flex;
        align-items: center;

        @each $icon in $icons {
          &.#{$icon}::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 12px;
            background: url('/static/images/residences/#{$icon}.svg')
              no-repeat
              center;
            background-size: contain;
          }
        }
      }
    }

    &--show {
      margin-top: 24px;
      padding: 15px 35px;
      background: #fff;
      border: 1px solid #eae5e1;
      border-radius: 7px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #4264fd;
    }
  }
}
