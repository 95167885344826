.contact-item {
  display: flex;
  gap: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding-left: 26px;
  color: #2f1f19;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  &::before {
    content: '';
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }

  & + & {
    margin-top: 15px;
  }

  &--timetable {
    font-weight: 400;

    &::before {
      background-image: url('/static/images/clock.svg');
      width: 18px;
      height: 18px;
      margin: auto;
    }
  }

  &--address {
    color: #4264fd;

    &::before {
      background-image: url('/static/images/map_row.svg');
      width: 16px;
      height: 20px;
    }
  }
}
