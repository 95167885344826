$border: 1px solid $colour-grey-wild-sand;

.wallet-pay-input {
  padding: 20px 15px;
  margin-bottom: 15px;
  background-color: $colour-white;
  border-bottom: $border;
  font-size: 14px;

  &__title {
    display: block;
    color: $colour-heathered-grey;
    margin-bottom: 2px;
  }

  &__error-message {
    color: $colour-red;
  }

  input {
    display: block;
    width: 100%;
    height: 39px;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    color: $colour-cocoa-brown;
    border-width: 0 0 1px;
    border-color: $colour-grey-wild-sand;
    border-radius: 0;
    box-sizing: border-box;
    outline: none;
  }

  input::placeholder {
    font-size: 14px;
    font-weight: 400;
  }

  &--error {
    input {
      border-bottom: 1px solid $colour-red;
    }
  }

  &__options {
    display: flex;
    gap: 15px;
    margin-top: 15px;
  }
}
