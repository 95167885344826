.drawer {
  $drawer: &;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: all linear 0.4s;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10002;

    .drawer--opened & {
      visibility: visible;
      opacity: 1;
    }

    #{$drawer}-nav &::after {
      background-image: url('/static/images/drawer/drawer-close.svg');
      background-size: cover;
      content: '';
      display: inline-block;
      width: get-vw(20px);
      height: get-vw(20px);
      position: fixed;
      left: 80%;
      top: get-vw(15px);
      margin-left: get-vw(15px);
    }
  }

  &__content {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 10003;
    transition: box-shadow, transform cubic-bezier(0, 0.15, 0, 1) 0.4s;

    #{$drawer}-nav & {
      overflow-y: auto;
      display: block;
      top: 0;
      width: 80%;
      background-color: #2e2e2e;
      transform: translateX(-100%);
    }

    #{$drawer}-sort & {
      border-radius: 15px 15px 0 0;
      background-color: $colour-whisper;
      transform: translateY(100%);
    }
  }

  &--opened {
    &#{$drawer}-nav #{$drawer}__content {
      transform: translateX(0);
    }

    &#{$drawer}-sort #{$drawer}__content {
      transform: translateY(0);
    }
  }
}

@media only screen and (min-width: 480px) {
  .drawer {
    &__overlay {
      .drawer-nav &::after {
        width: 20px;
        height: 20px;
        top: 15px;
        margin-left: 15px;
      }
    }
  }
}
