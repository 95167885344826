.wallet-pay-input {
  &__option {
    padding: 10px 15px;
    border-radius: 20px;
    border: 1px solid $colour-grey-wild-sand;
    color: $colour-cocoa-brown;
    font-size: 14px;

    &--active {
      color: $colour-neon-blue;
      border: 1px solid $colour-neon-blue;
    }
  }
}
