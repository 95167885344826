.item-price {
  font-size: 16px;
  text-align: center;
  background-color: #936447;
  padding: 8px 9px;
  display: inline-block;
  vertical-align: text-bottom;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: 600;
  color: #fff;
  margin-bottom: 3px;
}
