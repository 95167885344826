.residence-not-found {
  width: 100%;
  padding: 40px 15px;
  background-color: #fff;

  i {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  &__image {
    background-image: url('/static/images/residences/residence-not-found.svg');
    width: 150px;
    height: 150px;
  }

  span {
    display: block;
    margin-bottom: 15px;
    text-align: center;
    font-family: $helvetica;
  }

  &__title {
    color: #ff586d;
    font-size: 16px;
    font-weight: 700;
  }

  &__btn {
    a {
      text-decoration: none;
      text-underline: none;
      display: block;
    }

    &-link {
      width: 100%;
      padding: 15px;
      font-size: 15px;
      height: 48px;
      color: #fff;
      text-align: center;
      border-radius: 7px;
      background-color: #4264fd;
    }
  }
}
