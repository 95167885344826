$color: #2f1f19;

.cur-item {
  &-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    height: 60px;
    width: 100%;

    &__left {
      position: relative;
      width: 40px;
      height: 60px;

      &-btn {
        position: absolute;
        left: -15px;
      }
    }

    &__right {
      width: 53px;
    }
  }
}
