.infrastructure {
  &-container {
    margin: 0 -15px;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2f1f19;
    margin: 0 15px 20px;
  }

  &-i {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    overflow-x: auto;
    font-family: $helvetica;

    &__item {
      margin-left: 15px;
      max-width: 120px;
      box-sizing: border-box;

      &:last-of-type {
        margin-right: 15px;
      }

      img {
        width: 120px;
        height: 120px;
        border-radius: 7px;
        overflow: hidden;
      }

      &__title {
        @include multiline-ellipsis(15px, 1.1, 2);
        font-weight: 500;
        line-height: 18px;
        color: #2f1f19;
        margin: 15px 0 4px;
      }

      &__description {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #98918a;

        &::before {
          content: '';
          background-image: url('/static/images/residences/distance.svg');
          display: inline-block;
          width: 9px;
          height: 13px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 5px;
        }
      }
    }
  }
}
