.new-search-empty-locations {
  display: flex;
  flex-direction: column;
  height: 80vh;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;

  &__text {
    text-align: center;
    width: 70%;
    font-size: 15px;
    color: $colour-heathered-grey;
    margin-bottom: 40px;
    font-weight: 500;
  }

  &__link {
    text-align: center;
    width: 100%;
    color: #4264fd;
    font-size: 15px;
    text-decoration: none;
    border-radius: 14px;
    border: 1px solid $border-color;
    padding: 15px;
    height: 50px;
    background: $colour-white;
    margin-bottom: 60px;
  }
}
