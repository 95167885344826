.agency {
  &-section {
    padding: 0 0 20px;
    background: #fff;

    &--contacts {
      padding-top: 20px;
    }

    &--bordered {
      border-bottom: 1px solid #efe5e1;
    }
  }
}
