.calculator-range {
  font-family: $helvetica;
  position: relative;
  margin-bottom: 15px;

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    height: 56px;
    width: 100%;
    padding: 0 15px;
    border-radius: 10px 10px 0 0;
    color: #09101d;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #d4d6db;
    background-color: #fff;

    input {
      outline: none;
      height: 48px;
      width: 10%;
      font-size: 20px;
      text-align: right;
      color: #2e3131;
      background-color: inherit;
      border-width: 0;
    }

    input:focus {
      outline: none;
    }

    &-result {
      font-size: 20px;
      font-weight: 400;
    }

    span {
      display: block;
      color: #9496ac;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

      &:not(:first-of-type) {
        color: #25282b;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  input[type='range'] {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 10px + var(--ratio) * (100% - 10px));
  }

  input[type='range'] {
    position: absolute;
    bottom: 0;
    appearance: none;
    background: #c9c9c9;
    cursor: pointer;
    height: 4px;
    width: 100%;
    margin: 0;
    // stylelint-disable-next-line
    -webkit-appearance: none;
    border-radius: 0 0 7px 7px;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #ec3342;
      border: 4px solid #fff;
      margin-top: -5px;
      // stylelint-disable-next-line
      -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      height: 4px;
      background: #c9c9c9;
      border: 0;
      box-shadow: none;
    }

    &::-webkit-slider-thumb:hover {
      background: #ec3342;
    }

    &:hover::-webkit-slider-runnable-track {
      background: #ec3342;
    }

    &::-webkit-slider-thumb:active {
      background: #ec3342;
    }

    &:active::-webkit-slider-runnable-track {
      background: #c9c9c9;
    }

    &::-webkit-slider-runnable-track {
      background:
        linear-gradient(#ec3342, #ec3342) 0 / var(--sx) 100% no-repeat,
        #c9c9c9;
    }

    &:hover::-webkit-slider-runnable-track {
      background:
        linear-gradient(#ec3342, #ec3342) 0 / var(--sx) 100% no-repeat,
        #c9c9c9;
    }

    &:active::-webkit-slider-runnable-track {
      background:
        linear-gradient(#ec3342, #ec3342) 0 / var(--sx) 100% no-repeat,
        #c9c9c9;
    }
  }
}
