$color: #2f1f19;

.owner-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 15px;
  font-family: $helvetica;

  &__content {
    color: $color;
    font-size: 15px;
    width: 90%;
    align-self: center;

    span {
      text-transform: capitalize;
      color: #98918a;
      font-size: 15px;
    }
  }

  &__pic {
    flex: none;
    margin-left: 10px;
    width: 60px;
    height: 60px;
    border-radius: 7px;
    border: 1px solid #eae5e1;
    overflow: hidden;
  }
}
