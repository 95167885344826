.profile-empty {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 40px;
  color: $colour-cocoa-brown;
  font-size: 15px;
  font-weight: 500;

  &__desc {
    color: $colour-heathered-grey;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
  }

  i {
    display: block;
    align-self: center;
    margin-bottom: 20px;
  }

  span {
    display: block;
    align-self: center;
    margin-bottom: 15px;
  }
}
