.profile-payments-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 28px);
  height: 71px;
  padding: 15px 0;
  gap: 20px;

  &-container {
    display: block;
    width: 100%;
    position: relative;
    text-decoration: none;

    i {
      position: absolute;
      right: -5px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    font-size: 15px;

    span {
      display: block;
    }

    &-title {
      color: $colour-cocoa-brown;
      margin-bottom: 5px;
    }

    &-desc {
      color: $colour-heathered-grey;
    }

    &:first-child {
      align-items: flex-start;
    }

    &:last-child {
      align-items: flex-end;

      span:first-of-type {
        font-weight: 500;
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid $colour-grey-wild-sand;
  }
}
