.profile-packages {
  padding: 10px 15px 20px;
  color: $colour-cocoa-brown;

  &__title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
