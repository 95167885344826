.contact-modal {
  &__container {
    padding: 20px 36px;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2f1f19;
    padding-bottom: 20px;
  }

  &__numbers {
    &-i {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #2f1f19;
      display: block;
      text-decoration: none;
      text-align: left;

      &::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        background-image: url('/static/images/residences/brown-phone.svg');
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 16px;
      }

      &:first-of-type {
        margin: 20px 0 8px;
      }
    }
  }

  &__email,
  &__website {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #2f1f19;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 16px;
      background-image: url('/static/images/residences/mail.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 15px;
    }
  }

  &__email {
    margin: 30px 0 15px;
  }

  &__website {
    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url('/static/images/residences/site.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 15px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 6px 5px 4px;
    font-size: 32px;
    line-height: 19px;
    font-weight: 400;
    color: #98918a;
    text-decoration: none;
  }
}
