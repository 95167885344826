.search-bar {
  &__form {
    width: 100%;
    border-bottom: 0.5px solid $colour-grey-wild-sand;

    ::-webkit-scrollbar {
      display: none;
    }
  }
}
