.contacts {
  &-item {
    display: flex;
    width: 100%;
    gap: 10px;
    height: 55px;
    align-items: center;
    border-bottom: 1px solid #eae5e1;
    text-decoration: none;

    &__icon {
      display: inline-block;
      width: 35px;
      text-align: center;
    }

    &__title {
      color: #2f1f19;
      font-size: 15px;
      font-weight: 400;
    }
  }

  &-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid transparent;
    border-top-color: #af8329;
    border-left-color: #af8329;
    border-radius: 50%;
    animation: spinner 1s linear infinite;

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
    }
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
