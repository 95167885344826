.section {
  $this: &;

  &-block {
    padding: 0 0 5px;

    &--vipped {
      padding-top: 7px;
    }
  }

  &-page {
    #{$this}-header {
      margin: 0 0 10px;
    }
  }

  &-white {
    background-color: #fff;
  }

  &-gray {
    background-color: #f2f2f2;
  }

  &-main {
    background-color: #fdfcfc;
  }
}
