.ap-gallery {
  background-color: #fff;
  overflow: hidden;

  &__thumbnail {
    height: 60px;
    width: 60px;
    border: 1px solid #eae5e1;
    border-radius: 7px;
    overflow: hidden;

    &:not(:first-child) {
      display: none;
    }

    &-img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}
