.profile-balance {
  padding: 10px 15px 15px;
  margin-top: 60px;

  &__items {
    display: flex;
    justify-content: space-between;
    gap: 9px;
  }

  &__block {
    width: 100%;
    padding: 15px;
    border-radius: 14px;
    border: 1px solid $colour-grey-wild-sand;
    background: $colour-white;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    height: 20px;
    margin-bottom: 2px;

    &-title {
      width: 100%;

      span {
        color: $colour-heathered-grey;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 85px;

        @media screen and (min-width: 370px) {
          width: 110px;
        }

        @media screen and (min-width: 414px) {
          width: 135px;
        }
      }
    }

    &-dropdown {
      min-width: 20px;
      min-height: 20px;
      position: relative;

      i {
        position: absolute;
        align-items: center;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__count {
    color: $colour-cocoa-brown;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__button {
    height: 40px;
  }
}
