.profile-ad-info {
  display: flex;
  align-self: center;
  gap: 10px;
  color: $colour-cocoa-brown;

  &-container {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    padding: 15px;
    gap: 10px;
    position: relative;
    background-color: $colour-white;
  }

  &__image {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    border-radius: 7px;
    overflow: hidden;
    align-self: center;
  }

  &__info {
    width: 75%;
    align-self: center;
    font-size: 14px;

    span {
      display: block;
    }
  }

  &__price {
    font-size: 15px;
    font-weight: 700;
    margin-top: 2px;
  }

  &__arrow {
    align-self: center;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid $colour-grey-wild-sand;
    width: 100%;
    display: block;
  }
}
