.prom-section {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__not-author-container {
    gap: 5px;
    flex-direction: row;
  }

  &__not-author-alignment {
    padding: 15px 0;
  }

  &__item {
    position: relative;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 7px;
    border: 1px solid #eae5e1;
    background-color: #f8f8f8;
    font-family: $helvetica;

    &--not-author {
      padding: 0 10px;
      height: 50px;
      gap: unset;

      @media screen and (max-width: 350px) {
        padding: 3px 8px;
      }
    }

    &--not-author-icon {
      align-self: center;

      svg {
        margin-top: -3px;
      }
    }

    &-content {
      color: #2f1f19;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--not-author-content {
        justify-content: center;
      }

      &_title {
        font-size: 16px;
        font-weight: 700;
        margin-right: 10px;
      }

      &--not-author-title {
        font-size: 13px;
        font-weight: 700;

        @media screen and (max-width: 350px) {
          font-size: 10px;
        }
      }

      &--not-author-price {
        color: #4264fd;
        font-size: 11px;
        font-weight: 500;

        @media screen and (max-width: 350px) {
          font-size: 8px;
        }
      }

      svg {
        margin-top: -3px;
      }

      &_desc {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 400;

        &--padding {
          padding-bottom: 23px;
        }
      }

      &_label {
        display: flex;
        gap: 2px;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 5px;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.55px;
        text-transform: uppercase;
        color: #fff;
        border-radius: 0 7px;

        &--vip {
          background-color: #ff3d00;

          // stylelint-disable max-nesting-depth
          .text-with-icon {
            gap: 5px;
          }
        }

        &--featured {
          background-color: #ff9f2b;
        }
      }
    }

    &-button {
      min-width: 100px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      background-color: #4264fd;
      color: #fff;
      font-size: 15px;
      white-space: nowrap;
    }

    .text-with-icon {
      display: flex;
      gap: 2px;
      align-items: center;

      &::before {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        background-image: url('/static/images/icon-plus--white.svg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
