.back-button {
  position: absolute;
  left: 0;
  height: 60px;
  width: 40px;

  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }

  svg {
    transform: rotate(180deg);
  }
}
