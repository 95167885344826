$color: #2f1f19;

.sizes-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-family: $helvetica;
  color: $color;
  gap: 25px;

  &__val {
    font-size: 18px;
    font-weight: 500;
  }

  &__desc {
    color: #98918a;
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
  }
}
