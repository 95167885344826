.message-status {
  width: 100%;
  color: #fff;

  span {
    font-family: $helvetica;
    font-size: 15px;
  }

  &__expired {
    padding: 15px;
    background-color: #98918a;
  }

  &__pending {
    padding: 15px;
    background-color: #ffb900;
    display: flex;
    flex-direction: column;
    gap: 15px;

    span {
      display: block;
    }
  }

  &__limited {
    padding: 15px;
    background-color: #98918a;

    span {
      display: block;
    }
  }

  &__rejected {
    padding: 15px;
    background-color: #ff586d;

    span {
      display: block;
    }
  }
}
