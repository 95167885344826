.ap-filter {
  $this: &;
  padding-top: 30px;

  &__content {
    &.hidden {
      overflow: hidden;
    }

    &-btn {
      padding: 15px 35px;
      background: #fff;
      border: 1px solid #eae5e1;
      border-radius: 7px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #4264fd;
      margin: 35px 0 0;

      &.hidden {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2f1f19;
  }

  &__header {
    &-row {
      overflow-x: auto;
      display: flex;
      padding-bottom: 20px;
    }

    &-btn {
      margin-right: 5px;
      background-color: #fff;
      padding: 10px 15px;
      background: #fff;
      border: 1px solid #eae5e1;
      border-radius: 20px;
      display: inline-block;
      font-family: $helvetica;
      text-decoration: none;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #2f1f19;

      &.active {
        color: #fff;
        background: #4264fd;
        border: 1px solid #4264fd;
        border-radius: 20px;
      }
    }
  }

  &__container {
    &:not(:first-of-type) {
      border-top: 1px solid #eae5e1;
      padding-top: 15px;
      margin-top: 15px;
    }

    &.hidden {
      display: none;
    }

    #{$this}__flat {
      background-color: #fff;

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1 0 auto;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #2f1f19;
        max-width: 70%;
      }

      &-area {
        text-decoration-line: underline;
      }

      &-price {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 4px 0;

        &--label {
          display: block;
          font-size: 10px;
          font-weight: bold;
          color: #222;
          margin-left: -6px;
        }

        &__month {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #2f1f19;
          white-space: nowrap;
        }

        &__per-meter {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #98918a;
          margin-left: 10px;
          white-space: nowrap;
        }

        &__mortgage {
          background-color: #efefef;
          position: relative;
          font-size: 13px;
          padding: 4px 12px;
          text-align: center;
          color: #936447;
          line-height: 17px;
        }
      }

      &-photos {
        img {
          height: 60px;
          width: 60px;
          border: 1px solid #eae5e1;
          border-radius: 7px;
          overflow: hidden;
        }
      }

      &-top {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
      }

      &-bottom {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #2f1f19;

        span:not(:first-of-type)::before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 4px;
          background: #eae5e1;
          border-radius: 50%;
          margin: 0 5px;
          position: relative;
          bottom: 2px;
        }
      }
    }
  }
}
