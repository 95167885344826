.cur-item-desc {
  margin: 20px 0;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 18px;
    font-family: $helvetica;
    font-weight: 500;
    color: #2f1f19;
  }
}
