.option {
  &-container {
    display: flex;
    margin-bottom: 15px;
    padding: 0 15px;
    height: 60px;
    border-radius: 14px;
    border: 1px solid $colour-grey-wild-sand;
    align-items: center;
    justify-content: flex-start;
  }

  &__text {
    font-size: 15px;
    font-weight: 400;
    margin-left: 15px;
    color: $colour-cocoa-brown;
  }
}
