.alert-message {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  padding: 15px;
  font-family: $helvetica;
  border-radius: 7px;
  border: 1px solid #ff586d;
  background-color: #ffe6e5;

  i {
    align-self: center;
  }

  &__content {
    color: #2f1f19;
    font-size: 15px;
    width: 90%;
    align-self: center;

    &-title {
      color: #ff586d;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
}
