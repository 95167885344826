.show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: get-vw(45px) get-vw(44px);
  font-size: get-vw(12px);
  color: #fff;
  text-decoration: none;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.53);
  transition: opacity 0.7s;
  box-sizing: border-box;

  @include mq(480) {
    padding: 45px 44px;
    font-size: 12px;
  }
}

.image-link {
  position: relative;
  display: flex;
  z-index: 9;
  width: 100%;
  height: 100%;
}
