.video-section {
  padding-bottom: 30px;
  border-bottom: 1px solid #eae5e1;
  margin-top: 30px;

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2f1f19;
    font-family: $helvetica;
    margin-bottom: 20px;
  }

  &__content {
    display: flex;
    overflow-x: auto;
    margin: 0 -15px;
  }

  &__i {
    margin-left: 15px;
    border-radius: 7px;

    &:last-of-type {
      margin-right: 15px;
    }

    &-thumbnail {
      &-img {
        width: 200px;
        height: 112px;
        border-radius: 7px;
        overflow: hidden;
        background-size: cover;
      }

      &:not(:first-of-type) {
        display: none;
      }
    }

    &-title {
      @include multiline-ellipsis(15px, 1.1, 2);
      font-weight: 500;
      line-height: 18px;
      color: #2f1f19;
      margin: 15px 0 5px;
    }

    &-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #98918a;
    }
  }

  iframe {
    display: block;
    border-radius: 7px;
    overflow: hidden;
  }
}
