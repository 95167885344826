.profile-menu {
  color: $colour-cocoa-brown;

  &__item {
    display: flex;
    width: 100%;
    gap: 10px;
    height: 55px;
    padding: 0 15px;
    align-items: center;
    background-color: $colour-white;
    font-size: 15px;
    position: relative;

    i {
      display: inline-block;
      width: 20px;
      text-align: center;
    }
  }
}
