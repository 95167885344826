$title-color: #2f1f19;
$border-color: #eae5e1;

.new-checkbox {
  &-container {
    display: flex;
    margin-right: 10px;
    margin-bottom: 15px;
    padding: 9px 15px;
    border-radius: 100px;
    min-width: fit-content;
    border: 1px solid $border-color;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-size: 15px;
    font-weight: 400;
  }

  &-active {
    background-color: $colour-light-blue;
    border-color: #4264fd;
    color: #4264fd;
  }
}
