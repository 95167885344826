.calc-summary {
  font-family: $helvetica;

  &__cv {
    padding: 0 0 24px;
    color: #25282b;
    margin-top: 20px;

    &-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
    }

    &-persentages {
      display: flex;
      gap: 20px;
      padding: 25px 0 0;
      color: #9496ac;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      &-value {
        margin-top: 5px;
        color: #25282b;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }

      span {
        display: block;
      }
    }

    &-result {
      margin-top: 20px;
      color: #9496ac;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      &-sum {
        margin-top: 5px;
        color: #25282b;
        font-size: 32px;
        font-weight: 600;
        line-height: 36px;
      }

      span {
        display: block;
      }
    }
  }
}
