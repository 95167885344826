.profile-edit-form {
  &__fields {
    background-color: $colour-white;
    border-bottom: 1px solid $colour-grey-wild-sand;
    padding: 0 15px;
  }

  &__password {
    padding: 0;
  }
}
