$color: #98918a;

.breadcrumbs-section {
  width: 100%;
  height: 37px;
  overflow: hidden;
  padding: 10px 15px;
  border-top: 1px solid #eae5e1;
  background-color: #f8f8f8;

  &__list {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;

    &-i {
      position: relative;
      display: inline-block;
      margin-right: 20px;
      color: $color;
      font-size: 14px;
      font-family: $helvetica;

      a {
        color: $color;
        text-decoration: none;

        &::after {
          content: '.';
          color: $color;
          position: absolute;
          top: -4px;
          right: -10px;
          width: 3px;
          height: 3px;
        }
      }
    }
  }
}

.breadcrumbs-section li:last-child {
  margin-right: 0;
}

.breadcrumbs-section li:last-child a::after {
  display: none;
}
