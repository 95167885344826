.similar-ads {
  padding-top: 20px;
  background-color: #f6f4f1;

  &__container {
    background-color: #fff;
  }

  &__list {
    padding: 0 15px;
  }

  &__title {
    color: #2f1f19;
    font-family: $helvetica;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 15px 15px;
  }
}
