.profile-wallet-pay {
  button {
    margin: 5px 0;
  }

  .promotion__confirmation {
    font-size: 13px;
    color: #a49e97;
  }

  .wallet-pay-input__option {
    font-size: 15px;
  }
}
