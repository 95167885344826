.agency-header {
  display: flex;
  padding: 20px 0;
  background-color: #fff;
  color: #000;
  font-size: 15px;
  font-family: $helvetica;

  &__logo {
    min-width: 80px;
    height: 80px;
    border-radius: 7px;
    overflow: hidden;
    margin-right: 15px;
    align-self: center;
  }

  &__info {
    align-self: center;

    &-row {
      display: block;
    }

    &-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: #2f1f19;
      margin: 0 0 5px;
    }

    &-views {
      font-size: 14px;
      color: #98918a;
      margin-bottom: 2px;
    }
  }
}
