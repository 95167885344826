.section {
  &-list {
    @include items-list;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 10px 0 1px;

    &.vip-page-list {
      padding: 10px 10px 10px 1px;
    }

    &-bookmarks {
      justify-content: flex-start;
      margin: 0 auto;
      padding: 15px 15px 20px 6px;
      background-color: #fff;
      box-sizing: border-box;

      &__empty {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 85vh;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        text-align: center;
        color: #98918a;
        padding-left: 9px;

        &__text {
          margin-top: 30px;
        }
      }
    }
  }
}
