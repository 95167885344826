.modal__content--alert.webview {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  width: calc(100vw - 40px);
  border-radius: 14px;
  background: #fff;
  overflow-y: hidden;

  .alert-modal {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-content {
      display: flex;
      flex-direction: column;
      gap: unset;
      text-align: center;
      font-family: $helvetica;
      border: 0;

      &__icon {
        margin-bottom: 20px;

        .svg-icon--blue {
          fill: none;
        }
      }

      &__title {
        color: #2f1f19;
        font-size: 16px;
        line-height: 19.54px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      &__message {
        color: #2f1f19;
        font-size: 16px;
      }

      p {
        margin: 0;
      }

      &__check {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        &__divider {
          border-style: dashed;
          color: #eae5e1;
          margin: 0;
        }

        &__row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 14px;
        }

        &__row-name {
          color: #98918a;
        }

        &__row-value {
          color: #2f1f19;
        }

        &__row-value.amount {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }

    &-btns {
      display: flex;
      justify-content: center;

      &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        cursor: pointer;
        border-radius: 7px;
        background-color: #dae8ff;
        color: #4264fd;

        &--blue {
          background-color: #4264fd;
          color: #fff;
        }
      }
    }
  }
}
