.profile-invoice {
  padding: 25px 15px;
  margin-bottom: 70px;
  background-color: $colour-white;
  border-bottom: 1px solid $colour-grey-wild-sand;

  &__title {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 500;
  }

  &__content {
    padding: 0 11px 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $colour-grey-wild-sand;

    &_row {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      font-size: 15px;
      color: $colour-heathered-grey;
      margin-bottom: 12px;

      span {
        display: block;

        &:last-child {
          color: $colour-cocoa-brown;
          text-align: right;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__summary {
    span {
      font-weight: 500;
      color: $colour-cocoa-brown;
    }
  }

  &__button {
    padding: 0 15px;
  }
}
