.profile-packages-payment {
  margin-top: 10px;
  height: calc(100vh - 60px);

  &__info {
    padding: 20px 15px 0;
    background-color: $colour-white;
    color: $colour-cocoa-brown;
    border-top: 1px solid $colour-grey-wild-sand;
    border-bottom: 1px solid $colour-grey-wild-sand;

    &-title {
      display: block;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .profile-package {
      padding: 20px;
      margin-bottom: 10px;
    }

    .profile-package__content {
      margin: 20px 0 0;
    }

    &_dates {
      padding: 15px 0 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-section {
        &-title {
          display: block;
          color: $colour-heathered-grey;
          font-size: 15px;
          margin-bottom: 15px;
        }

        &-periods {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
        }
      }
    }
  }

  &__summary {
    padding: 20px 15px;
    margin-top: 10px;
    border-top: 1px solid $colour-grey-wild-sand;
    border-bottom: 1px solid $colour-grey-wild-sand;
    background-color: $colour-white;
    color: $colour-cocoa-brown;

    &-title {
      display: block;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    &-results {
      padding: 15px;
      border-radius: 7px;
      background: $colour-placeholder-bg;

      :last-child {
        margin-bottom: 0;
      }

      &-row {
        display: flex;
        gap: 15px;
        margin-bottom: 10px;
        font-size: 15px;

        :first-child {
          width: 50%;
          color: $colour-heathered-grey;
        }

        :last-child {
          color: $colour-cocoa-brown;
        }
      }

      :last-of-type span {
        color: $colour-cocoa-brown;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  &__button {
    padding: 15px calc(env(safe-area-inset-bottom) + 15px) 15px;
    background-color: $colour-background-tertiary;

    button {
      height: 50px;
      border-radius: 14px;
    }
  }
}
