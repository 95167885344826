.owner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  &__img {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 7px;
  }

  &__name {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #2f1f19;
    font-family: $helvetica;
  }

  &__type {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #98918a;
    font-family: $helvetica;
  }
}
