.map {
  &-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 450px;
  }

  &__close-btn {
    position: absolute;
    top: 48px;
    right: 9px;
    padding: 6px 5px 4px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #000;
    font-size: 32px;
    line-height: 19px;
    font-weight: 400;
    font-family: Arial, sans-serif;
    color: #fff;
    text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.6);
    text-decoration: none;
  }
}
