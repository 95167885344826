$brown: $colour-cocoa-brown;

.profile-active-package {
  padding: 20px 15px;
  color: $brown;
  font-size: 18px;
  font-weight: 500;

  &__title {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__section {
    padding: 20px;
    border-radius: 7px;
    margin-bottom: 15px;
    background-color: #f6f7fa;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);

    &:last-child {
      margin-bottom: 0;
    }

    &-row {
      margin-bottom: 20px;

      &_discount {
        display: flex;
        gap: 10px;
        align-items: center;

        span {
          display: block;
        }

        :last-child {
          padding: 5px 10px;
          border-radius: 45px;
          background: $brown;
          color: $colour-white;
          font-size: 12px;
          font-weight: 400;
        }
      }

      &_progress {
        margin-top: 5px;
        width: 144px;
        height: 3px;
        border-radius: 5px;
        background-color: $colour-white;

        &-bar {
          height: 100%;
          border-radius: 5px;
          background-color: $brown;
        }
      }

      &_description {
        display: block;
        color: $colour-heathered-grey;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 5px;
      }
    }
  }

  &__empty {
    color: $brown;
    font-size: 15px;
    font-weight: 400;
  }
}
