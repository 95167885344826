.profile-payments-list {
  background-color: $colour-white;
  height: 100%;
  padding: 0 5px;
  margin-top: 15px;

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 96px);

    &--small-header {
      height: calc(100vh - 60px);
    }
  }
}
