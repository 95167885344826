.profile-modal {
  &__item {
    padding: 15px 20px;
    border-radius: 7px;
    background-color: $colour-background-tertiary;
    margin-bottom: 15px;
    color: $colour-cocoa-brown;

    span {
      display: block;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 15px;

      &_balance {
        font-weight: 700;
      }
    }

    &-description {
      color: $colour-heathered-grey;
      font-size: 14px;
    }
  }

  &__business-balance {
    color: $colour-cocoa-brown;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
  }

  &--bordered:last-of-type {
    border: 1px solid $colour-cocoa-brown;
    background-color: $colour-white;

    span {
      &:first-child {
        font-weight: 700;
      }
    }
  }

  &--bordered:last-of-type &--bordered-header {
    margin-bottom: 5px;
  }
}
