.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $helvetica;

  &__i {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #98918a;
    flex: 1;

    li {
      white-space: nowrap;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &--right {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #2f1f19;
    }
  }
}
